/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  ADD_PRODUCT (state, item) {
    state.Products.unshift(item)
  },
  SET_PRODUCT (state, products) {
    state.Products = products
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_PRODUCT (state, product) {
    const productIndex = state.Products.findIndex((p) => p.id === product.id)
    Object.assign(state.Products[productIndex], product)
  },
  REMOVE_PRODUCT (state, productId) {
    const ProductIndex = state.Products.findIndex((p) => p.id === productId)
    state.Products.splice(ProductIndex, 1)
  }
}
