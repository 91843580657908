<template>
    <div class="service-grid-item">
      <div v-if="dataType == 'category'">
        <div class="service-grid-item__image">
            <div class="service-grid-item__image-wrapper">
                <router-link :to="{name:'Service-child', params:{ cateId: service.id, cateTitle: service.title}}">
                    <img :src="BaseUrl + service.image" class="img-fluid" style="background: linear-gradient(to right, #30CFD0 0%, #330867 100%);" alt="service thumb">
                </router-link>
            </div>
            <div class="icon">
                <i class="flaticon-002-welding"></i>
            </div>
        </div>
        <div class="service-grid-item__content">
            <h3 class="title">
                <router-link :to="{name:'Service-child', params:{ cateId: service.id, cateTitle: service.title}}">
                    {{ service.title }}
                </router-link>
            </h3>
            <div class="subtitle warp-text">{{ service.description }}</div>
          <br>

            <router-link class="see-more-link" :to="{name:'Service-child', params:{ cateId: service.id, cateTitle: service.title}}">SEE MORE</router-link>
        </div>
      </div>
      <div v-if="dataType == 'products'">

        <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" v-if="products" v-for="serv in products" :key="serv.id">
              <div class="tile">
                <div class="wrapper">
                  <div class="header">{{ CateTitle }}</div>

                  <div class="banner-img">
                    <img :src="BaseUrl + serv.image" :alt=" serv.title ">
                  </div>

                  <div class="dates">
                    <div class="start">
                      <strong>SERIAL</strong> {{ serv.serial}}
                      <span></span>
                    </div>

                    <div class="ends">
                      <strong>TITLE</strong> {{ serv.title }}
                    </div>
                  </div>

                  <div class="stats">

                    <div v-if="serv.body" class="mt-3 stats1">
                      <strong>Body material</strong> {{ serv.body }}
                    </div>

                    <div v-if="serv.pn" class="mt-3 stats1">
                      <strong>PN (End)</strong> {{ serv.pn }}
                    </div>

                    <div v-if="serv.end" class="mt-3 stats1">
                      <strong>End</strong> {{ serv.end }}
                    </div>

                    <div v-if="serv.contact" class="mt-3 stats1">
                      <strong>Contact sealing</strong> {{ serv.contact }}
                    </div>

                    <div v-if="serv.gate_material" class="mt-3 stats1">
                      <strong>Gate material</strong> {{ serv.gate_material }}
                    </div>

                    <div v-if="serv.operating_system" class="mt-3 stats1">
                      <strong>Operating system</strong> {{ serv.operating_system }}
                    </div>

                    <div v-if="serv.Check_valve_material" class="mt-3 stats1">
                      <strong>Check valve material</strong> {{ serv.Check_valve_material }}
                    </div>

                    <div v-if="serv.Disc_material" class="mt-3 stats1">
                      <strong>Disc material</strong> {{ serv.Disc_material }}
                    </div>

                    <div v-if="serv.Wedge_material" class="mt-3 stats1">
                      <strong>Wedge material</strong> {{ serv.Wedge_material }}
                    </div>

                    <div v-if="serv.Connection" class="mt-3 stats1">
                      <strong>Connection</strong> {{ serv.Connection }}
                    </div>

                    <div v-if="serv.Float_material" class="mt-3 stats1">
                      <strong>Float material</strong> {{ serv.Float_material }}
                    </div>

                    <div v-if="serv.Ball_material" class="mt-3 stats1">
                      <strong>Ball material</strong> {{ serv.Ball_material }}
                    </div>

                    <div v-if="serv.Bonnet_material" class="mt-3 stats1">
                      <strong>Bonnet material</strong> {{ serv.Bonnet_material }}
                    </div>

                    <div v-if="serv.Diaphragm_material" class="mt-3 stats1">
                      <strong>Diaphragm material</strong> {{ serv.Diaphragm_material }}
                    </div>

                    <div v-if="serv.Bulb_length" class="mt-3 stats1">
                      <strong>Bulb length</strong> {{ serv.Bulb_length }}
                    </div>

                    <div v-if="serv.SCALE" class="mt-3 stats1">
                      <strong>SCALE</strong> {{ serv.SCALE }}
                    </div>

                    <div v-if="serv.Category" class="mt-3 stats1">
                      <strong>Category</strong> {{ serv.Category }}
                    </div>

                    <div v-if="serv.Glass" class="mt-3 stats1">
                      <strong>Glass</strong> {{ serv.Glass }}
                    </div>

                    <div v-if="serv.Protection" class="mt-3 stats1">
                      <strong>Protection</strong> {{ serv.Protection }}
                    </div>

                    <div v-if="serv.Output" class="mt-3 stats1">
                      <strong>Output</strong> {{ serv.Output }}
                    </div>

                    <div v-if="serv.Gasket" class="mt-3 stats1">
                      <strong>Gasket</strong> {{ serv.Gasket }}
                    </div>

                    <div v-if="serv.Mounting" class="mt-3 stats1">
                      <strong>Mounting</strong> {{ serv.Mounting }}
                    </div>

                    <div v-if="serv.Internal_coating" class="mt-3 stats1">
                      <strong>Internal coating</strong> {{ serv.Internal_coating }}
                    </div>

                    <div v-if="serv.Max_pressure" class="mt-3 stats1">
                      <strong>Max pressure</strong> {{ serv.Max_pressure }}
                    </div>

                    <div v-if="serv.Stem_material" class="mt-3 stats1">
                      <strong>Stem material</strong> {{ serv.Stem_material }}
                    </div>

                    <div v-if="serv.Swing_material" class="mt-3 stats1">
                      <strong>Swing material</strong> {{ serv.Swing_material }}
                    </div>

                    <div v-if="serv.PN_Raccordement" class="mt-3 stats1">
                      <strong>PN Raccordement</strong> {{ serv.PN_Raccordement }}
                    </div>

                    <div v-if="serv.Obturator_material" class="mt-3 stats1">
                      <strong>Obturator material</strong> {{ serv.Obturator_material }}
                    </div>

                    <div v-if="serv.Spring_material" class="mt-3 stats1">
                      <strong>Spring material</strong> {{ serv.Spring_material }}
                    </div>

                    <div v-if="serv.Screen_material" class="mt-3 stats1">
                      <strong>Screen material</strong> {{ serv.Screen_material }}
                    </div>

                    <div v-if="serv.Needle_material" class="mt-3 stats1">
                      <strong>Needle material</strong> {{ serv.Needle_material }}
                    </div>

                    <div v-if="serv.Sleeve_material" class="mt-3 stats1">
                      <strong>Sleeve material</strong> {{ serv.Sleeve_material }}
                    </div>

                    <div v-if="serv.Type" class="mt-3 stats1">
                      <strong>Type</strong> {{ serv.Type }}
                    </div>

                    <div v-if="serv.Type_OF_FUNCTION" class="mt-3 stats1">
                      <strong>Type OF FUNCTION</strong> {{ serv.Type_OF_FUNCTION }}
                    </div>

                    <div v-if="serv.Tube_materiel" class="mt-3 stats1">
                      <strong>Tube materiel</strong> {{ serv.Tube_materiel }}
                    </div>

                    <div v-if="serv.Bolts_and_nuts" class="mt-3 stats1">
                      <strong>Bolts and nuts</strong> {{ serv.Bolts_and_nuts }}
                    </div>

                    <div v-if="serv.Thickness" class="mt-3 stats1">
                      <strong>Thickness</strong> {{ serv.Thickness }}
                    </div>

                    <div v-if="serv.Working_pressure" class="mt-3 stats1">
                      <strong>Working pressure</strong> {{ serv.Working_pressure }}
                    </div>

                    <div v-if="serv.Seat" class="mt-3 stats1">
                      <strong>Seat</strong> {{ serv.Seat }}
                    </div>

                    <div v-if="serv.Running_time" class="mt-3 stats1">
                      <strong>Running time</strong> {{ serv.Running_time }}
                    </div>

                    <div v-if="serv.Voltage" class="mt-3 stats1">
                      <strong>Voltage</strong> {{ serv.Voltage }}
                    </div>

                    <div v-if="serv.Regulation" class="mt-3 stats1">
                      <strong>Regulation</strong> {{ serv.Regulation }}
                    </div>

                  <div class="footer">
                    <a target="_blank" :href="BaseUrl + serv.datasheet" class="Cbtn Cbtn-primary">DataSheet</a>
<!--                    <a href="#" class="Cbtn Cbtn-danger">Delete</a>-->
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    </div>
</template>

<script>
    import data from "@/data/service.json";

    export default {
      props: ['service', 'dataType', 'cateTitle'],
      methods: {},
      data() {
        return {
          BaseUrl: 'https://titos.com.es/backend/images/upload/',
          Services: [],
        }
      },
      computed: {
        products(){
          // for (let i = 0; this.service.length > i ; i++)
          // {
          //   this.Services.push({
          //     'i':i
          //   })
          //   for (const [key, value] of Object.entries(this.service[i])) {
          //     this.Services.[i].push(
          //         {'service':
          //               {
          //                 'key': key,
          //                 'value': value
          //               }
          //         })
          //   }
          //   // console.log(this.service[0])
          // }
          // console.log(this.Services);
          return this.service
        },
        CateTitle() {
          return this.$route.params.cateTitle
        }
        }
      }
</script>

<style scoped>
p {
  width: 100%;
  /*border: 1px solid;*/
  padding: 2px 5px;

  /* BOTH of the following are required for text-overflow */
  white-space: nowrap;
  overflow: hidden;
}

.overflow-visible {
  white-space: initial;
}

.overflow-clip {
  text-overflow: clip;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.warp-text {
  word-wrap: break-word;
}

.overflow-string {
  /* Not supported in most browsers,
     see the 'Browser compatibility' section below */
  text-overflow: " [..]";
}

/** { margin: 0px; padding: 0px; }*/
/*body {*/
/*  background: #ecf1f5;*/
/*  font:14px "Open Sans", sans-serif;*/
/*  text-align:center;*/
/*}*/

.tile{
  width: 100%;
  background:#fff;
  border-radius:5px;
  box-shadow:0px 2px 3px -1px rgba(151, 171, 187, 0.7);
  float:left;
  transform-style: preserve-3d;
  margin: 10px 5px;

}

.header{
  border-bottom:1px solid #ebeff2;
  padding:19px 0;
  text-align:center;
  color:#59687f;
  font-size:600;
  font-size:19px;
  position:relative;
}

.banner-img {
  padding: 5px 5px 0;
}

.banner-img img {
  width: 100%;
  border-radius: 5px;
}

.dates{
  border:1px solid #ebeff2;
  border-radius:5px;
  padding:20px 0px;
  margin:10px 20px;
  font-size:16px;
  color:#5aadef;
  font-weight:600;
  overflow:auto;
}
.dates div{
  float:left;
  width:50%;
  text-align:center;
  position:relative;
}
.dates strong,
.stats strong{
  display:block;
  color:#adb8c2;
  font-size:11px;
  font-weight:700;
}
.dates span{
  width:1px;
  height:40px;
  position:absolute;
  right:0;
  top:0;
  background:#ebeff2;
}
.stats{
  border-top:1px solid #ebeff2;
  background:#f7f8fa;
  overflow:auto;
  padding:15px 0;
  font-size:16px;
  color:#59687f;
  font-weight:600;
  border-radius: 0 0 5px 5px;
}
.stats1{
  /*border-top:1px solid #ebeff2;*/
}
.stats div{
  border-right:1px solid #ebeff2;
  width: 33.33333%;
  float:left;
  text-align:center
}

.stats div:nth-of-type(3){border:none;}

div.footer {
  text-align: right;
  position: relative;
  margin: 20px 5px;
}

div.footer a.Cbtn{
  padding: 10px 25px;
  background-color: #DADADA;
  color: #666;
  margin: 10px 2px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
}

div.footer a.Cbtn-primary{
  background-color: #5AADF2;
  color: #FFF;
}

div.footer a.Cbtn-primary:hover{
  background-color: #7dbef5;
}

div.footer a.Cbtn-danger{
  background-color: #fc5a5a;
  color: #FFF;
}

div.footer a.Cbtn-danger:hover{
  background-color: #fd7676;
}

</style>