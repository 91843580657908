<template>
    <div class="main-container">

        <Header @clicked="selectItemMenu" />

        <Breadcrumb :items="parentCateTitle ? itemsSelectedProductService : CaTitle ? itemsSelectedService :  itemsHomeService" title="Products" />
<!--{{parentCateTitle}}-->
<!--      {{parentCateTitle ? itemsSelectedProductService : CaTitle ? itemsSelectedService :  itemsHomeService}}-->
<!--      itemsSelectedProductService-->
<!--      parentCateTitle-->

        <div class="page-wrapper section-space--inner--120">
            <!--Service section start-->
            <div class="service-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="service-item-wrapper">
                                <div class="row">

                                <!--category-->
<!--                                  <h1 v-if="selectedCategory"> Categories </h1>-->
                                    <div v-if="selectedCategory" class="col-lg-4 col-md-6 col-12 section-space--bottom--30" type="category" v-for="service in selectedCategory" :key="service.id">
                                        <ServiceItem :service="service" data-type="category" />
                                    </div>


                                <!--products-->

<!--                                  <h1 v-if="selectedProducts"> Products </h1>-->
                                </div>
                            </div>
                        </div>
                    </div>

<!--                      <div v-if="selectedProducts"  class="section-space&#45;&#45;bottom&#45;&#45;30" type="product" v-for="service in selectedProducts" :key="service.id">-->
                        <h4 v-if="selectedCategory.length > 0 && selectedProducts.length > 0" class="mt-3"> Your Recent View Products</h4>
                  <ServiceItem :service="selectedProducts" dataType="products" :cateTitle="CaTitle" />
<!--                      </div>-->
                  <!--                                  {{selectedProducts}}-->

                </div>

            </div>
            <!--Service section end-->
        </div>

        <BrandCarousel addClass="grey-bg" />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/service.json'
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import ServiceItem from '../components/ServiceItem'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    import ModuleCategory from "@/store/titos-category/moduleCategoryList";
    import ModuleProduct from "@/store/titos-product/moduleTitosProduct";

    export default {
        components: {
            Header,
            Breadcrumb,
            ServiceItem,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
                CateTitle:'this.$route.params.cateTitle',
                itemsHomeService: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Products',
                        active: true
                    }
                ],

              // url: process.env.VUE_APP_URL
            }
        },
        metaInfo: {
            title: 'Titos - Products',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
        methods: {
        selectItemMenu (value) {

        this.$store.dispatch('categoryList/fetchSelectedListItems', value)

          // console.log(value) // someValue
        }
      },
      watch : {
        '$route' (to, from) {
          // react to route changes...
          if(this.$route.params.cateId > 0){
            this.$store.dispatch('categoryList/fetchSelectedListItems', this.$route.params.cateId)
            this.$store.dispatch('productList/fetchDataProductListItems', this.$route.params.cateId)
            // return this.$store.state.categoryList.selectCategories
          }
        }
      },
      computed: {
        selectedCategory(){
          if(this.$route.params.cateId > 0){
            return this.$store.state.categoryList.selectCategories
          }
          return this.$store.state.categoryList.Category
        },
        selectedProducts(){
          if(this.$route.params.cateId > 0){
            return this.$store.state.productList.Products
          }
          return this.$store.state.productList.Products
        },
        CaTitle(){
          return this.CateTitle = this.$route.params.cateTitle
          // return this.$route.params.cateTitle
        },
        parentCateTitle(){
          return this.$route.params.parentCateTitle
          // return this.$route.params.cateTitle
        },
        itemsSelectedService(){
          return[
            {
              text: 'Home',
              to: "/"
            },
            {
              text: 'Products',
              to: '/products'
            },
            {
              text: this.$route.params.cateTitle,
              active: true
            }
          ]
        } ,
        itemsSelectedProductService() {
          return[
            {
              text: 'Home',
              to: "/"
            },
            {
              text: 'Products',
              to: '/products'
            },
            {
              text: this.CaTitle,
              active: true
            },
            {
              text: this.parentCateTitle,
              active: true
            }
          ]
        },
      },
      created(){
        // console.log(this.cateTitle)
          if (!ModuleCategory.isRegistered){
            this.$store.registerModule('categoryList', ModuleCategory)
            ModuleCategory.isRegistered = true
            // console.log(123)
          }

          if (!ModuleProduct.isRegistered){
            this.$store.registerModule('productList', ModuleProduct)
            ModuleProduct.isRegistered = true
            // console.log(123)
          }

        if(this.$route.params.cateId > 0){
          this.$store.dispatch('categoryList/fetchSelectedListItems', this.$route.params.cateId)
          this.$store.dispatch('productList/fetchDataProductListItems', this.$route.params.cateId)
        }

        }
    }
</script>

