/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // addItem ({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     console.log({item})
  //     axios.post('/api/add_titos_product', item)
  //       .then((response) => {
  //         commit('ADD_PRODUCT', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchDataProductListItems ({ commit }, categoryId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/fetch_titos_product/${categoryId}`)
        .then((response) => {
          // console.log(response.data.data[0])
          commit('SET_PRODUCT', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // fetchEventLabels({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/apps/calendar/labels")
  //       .then((response) => {
  //         commit('SET_LABELS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  updateItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      console.log(item)
      axios.post(`/api/update_titos_product/${item.get('id')}`, item)
        .then((response) => {
          // commit('UPDATE_CATEGORY', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeItem ({ commit }, itemId) {
    // console.log(itemId)
    return new Promise((resolve, reject) => {
      axios.delete(`/api/delete_titos_product/${itemId}`)
        .then((response) => {
          commit('REMOVE_PRODUCT', itemId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
  // eventDragged({ commit }, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.post(`/api/apps/calendar/event/dragged/${payload.event.id}`, {payload: payload})
  //       .then((response) => {

  //         // Convert Date String to Date Object
  //         let event = response.data
  //         event.startDate = new Date(event.startDate)
  //         event.endDate = new Date(event.endDate)

  //         commit('UPDATE_EVENT', event)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
